import React from 'react';
import { Fade } from 'react-reveal';

import { roleToKorean } from '../../../../utils/roleToKorean';
import styles from './Contact.module.css';
import tel from '../../../../assets/icon/tel.svg';
import sms from '../../../../assets/icon/sms.svg';

const Contact = ({ dbData, style }) => {
  const { bride, groom } = dbData.familyInfo;

  const handleIconClick = (type, role, target) => {
    const number = dbData.familyInfo[type].find((e) => e.role === role)?.phone;

    switch (target) {
      case 'tel':
        window.location.href = `tel:${number}`;
        break;
      case 'sms':
        window.location.href = `sms:${number}`;
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.Contact} style={style}>
      <div className={styles.column}>
        <Fade bottom duration={1000}>
          <div className={styles.card}>
            <label>
              신랑 <b>{groom.find((e) => e.role === 'me')?.name}</b>
            </label>
            <div className={styles.icons}>
              <button
                type='button'
                onClick={() => handleIconClick('groom', 'me', 'tel')}
                style={{ backgroundColor: `rgba(${dbData.colors.deep}, 1)` }}
              >
                <img src={tel} alt='tel' />
              </button>
              <button
                type='button'
                onClick={() => handleIconClick('groom', 'me', 'sms')}
                style={{ backgroundColor: `rgba(${dbData.colors.deep}, 1)` }}
              >
                <img src={sms} alt='sms' />
              </button>
            </div>
          </div>
        </Fade>

        <Fade bottom duration={1500}>
          <div className={styles.parents_card}>
            <table>
              <tbody>
                {groom
                  .filter((e) => e.role !== 'me' && e.phone)
                  .map((parent) => (
                    <tr key={`groom${parent.role}`}>
                      <td>{roleToKorean[parent.role]}</td>
                      <td>{parent.name}</td>
                      <td>
                        <button type='button' onClick={() => handleIconClick('groom', parent.role, 'tel')}>
                          {/* <img src={tel} alt='tel' /> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-telephone-fill'
                            viewBox='0 0 16 16'
                          >
                            <path
                              fillRule='evenodd'
                              d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z'
                            />
                          </svg>
                          <span>전화하기</span>
                        </button>
                        <button type='button' onClick={() => handleIconClick('groom', parent.role, 'sms')}>
                          {/* <img src={sms} alt='sms' /> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-chat-left-text-fill'
                            viewBox='0 0 16 16'
                          >
                            <path d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z' />
                          </svg>
                          <span>문자하기</span>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Fade>
      </div>

      {bride.find((e) => e.role === 'me').phone && (
        <div className={styles.column}>
          <Fade bottom duration={1000}>
            <div className={styles.card}>
              <label>
                신부 <b>{bride.find((e) => e.role === 'me').name}</b>
              </label>
              <div className={styles.icons}>
                <button
                  type='button'
                  onClick={() => handleIconClick('bride', 'me', 'tel')}
                  style={{ backgroundColor: `rgba(${dbData.colors.deep}, 1)` }}
                >
                  <img src={tel} alt='tel' />
                </button>
                <button
                  type='button'
                  onClick={() => handleIconClick('bride', 'me', 'sms')}
                  style={{ backgroundColor: `rgba(${dbData.colors.deep}, 1)` }}
                >
                  <img src={sms} alt='sms' />
                </button>
              </div>
            </div>
          </Fade>

          <Fade bottom duration={1500}>
            <div className={styles.parents_card}>
              <table>
                <tbody>
                  {bride
                    .filter((e) => e.role !== 'me' && e.phone)
                    .map((parent) => (
                      <tr key={`bride${parent.role}`}>
                        <td>{roleToKorean[parent.role]}</td>
                        <td>{parent.name}</td>
                        <td>
                          <button type='button' onClick={() => handleIconClick('bride', parent.role, 'tel')}>
                            {/* <img src={tel} alt='tel' /> */}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-telephone-fill'
                              viewBox='0 0 16 16'
                            >
                              <path
                                fillRule='evenodd'
                                d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z'
                              />
                            </svg>
                            <span>전화하기</span>
                          </button>
                          <button type='button' onClick={() => handleIconClick('bride', parent.role, 'sms')}>
                            {/* <img src={sms} alt='sms' /> */}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-chat-left-text-fill'
                              viewBox='0 0 16 16'
                            >
                              <path d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z' />
                            </svg>
                            <span>문자하기</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default Contact;
