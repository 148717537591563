import React from 'react';

const SetInvitation = ({ styles }) => {
  return (
    <div className={styles.SetInvitation}>
      <input type='text' defaultValue='초대의 글' />
      <textarea />
    </div>
  );
};

export default SetInvitation;
