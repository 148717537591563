import React, { Suspense } from 'react';

import useSettingReducer from '../../hooks/useSettingReducer';

import styles from './Designer.module.css';
import Loading from '../Common/Loading/Loading';
import Header from '../Common/Header/template_color_preview/Header';
import Title from '../MainComponents/Title/template_simple_1/Title';
import Gallery from '../MainComponents/Gallery/template_simple_1/Gallery';
import ornament from '../../assets/deco/path60.svg';
import Invitation from '../MainComponents/Invitation/template_simple_1/Invitation';
import CalendarColorPreview from '../MainComponents/Calendar/template_color_preview/Calendar';
import CalendarTemplatesimple3 from '../MainComponents/Calendar/template_simple_3/Calendar';
import CalendarTemplatesimple4 from '../MainComponents/Calendar/template_simple_4/Calendar';
import CalendarTemplatesimple2 from '../MainComponents/Calendar/template_simple_2/Calendar';
import DdayTemplatesimple1 from '../MainComponents/Dday/template_simple_1/Dday';
import DdayTemplatesimple3 from '../MainComponents/Dday/template_simple_3/Dday';
import Contact from '../MainComponents/Contact/template_simple_1/Contact';
import Footer from '../Common/Footer/template_simple_1/Footer';

const Preview = () => {
  const { colorData, settingComponentData, weddingData } = useSettingReducer();

  const calendarComponentObj = {
    template_color_preview: (
      <>
        <img src={ornament} alt='ornament' className={styles.ornament} />
        <CalendarColorPreview dbData={{ ...weddingData, colors: { ...colorData } }} />
      </>
    ),
    template_simple_2: (
      <>
        <img src={ornament} alt='ornament' className={styles.ornament} />
        <CalendarTemplatesimple2 dbData={{ ...weddingData, colors: { ...colorData } }} />
      </>
    ),
    template_simple_3: <CalendarTemplatesimple3 dbData={{ ...weddingData, colors: { ...colorData } }} />,
    template_simple_4: <CalendarTemplatesimple4 dbData={{ ...weddingData, colors: { ...colorData } }} />,
  };

  const ddayComponentObj = {
    template_simple_1: <DdayTemplatesimple1 dbData={{ ...weddingData, colors: { ...colorData } }} />,
    template_simple_3: <DdayTemplatesimple3 dbData={{ ...weddingData, colors: { ...colorData } }} />,
  };

  return (
    <div style={{ backgroundColor: `rgb(${colorData.light})`, overflowY: 'scroll', boxSadow: '0 0 20px -4px #aaaa' }}>
      <Suspense fallback={<Loading />}>
        <div className={styles.Preview}>
          <Header dbData={{ ...weddingData, colors: { ...colorData } }} />
          <div className={styles.contents}>
            <section id='title'>
              <h1 className='menu' style={{ display: 'none' }}>
                색상 미리보기
              </h1>
              {/* <Title dbData={{ ...weddingData, colors: { ...colorData } }} /> */}
            </section>
            <section id='invitation'>
              <h1 className='menu' style={{ display: 'none' }}>
                초대사
              </h1>
              <Invitation dbData={{ ...weddingData, colors: { ...colorData } }} />
            </section>
            <section id='calendar'>
              <h1 className='menu' style={{ display: 'none' }}>
                캘린더
              </h1>
              {calendarComponentObj[settingComponentData.calendar]}
              {/* {ddayComponentObj[settingComponentData.dday]} */}
            </section>
            <section id='gallery'>
              <div className={styles.line} />
              <h1 className='menu'>갤러리</h1>
              {/* <Gallery dbData={{...weddingData, colors: {...colorData}}} /> */}
            </section>
            <section id='contact'>
              <div className={styles.line} />
              <h1 className='menu'>연락처</h1>
              <Contact dbData={{ ...weddingData, colors: { ...colorData } }} />
            </section>
          </div>
          <Footer dbData={{ ...weddingData, colors: { ...colorData } }} />
        </div>
      </Suspense>
    </div>
  );
};

export default Preview;
