import React from 'react';
import { Fade } from 'react-reveal';

import styles from './Invitation.module.css';

const Invitation = ({ dbData, header = '초대의 글', noFooter, enter, style }) => {
  const backgroundImage = dbData.backgrounds.invitation;

  const { bride, groom } = dbData.familyInfo;

  const groomDadInfo = groom.find((e) => e.role === 'dad') || {};
  const groomMomInfo = groom.find((e) => e.role === 'mom') || {};
  const brideDadInfo = bride.find((e) => e.role === 'dad') || {};
  const brideMomInfo = bride.find((e) => e.role === 'mom') || {};

  return (
    <div
      className={`${styles.Invitation} ${backgroundImage && styles.withBackground}`}
      style={{
        ...style,
        backgroundImage: backgroundImage
          ? `url(${process.env.PUBLIC_URL}/images/gallery/${dbData.alias}/${backgroundImage})`
          : undefined,
        backgroundColor: `rgba(${dbData.colors.main}, 1)`,
      }}
    >
      <Fade bottom duration={1000}>
        <h1 style={{ display: header === 'none' ? 'none' : 'inherit' }}>{header}</h1>
      </Fade>
      <Fade bottom duration={1200}>
        <div
          className={styles.message}
          style={{ marginTop: header === 'none' ? '2rem' : '0', marginBottom: noFooter ? '2rem' : '0' }}
        >
          {dbData.invitationHTML
            ? dbData.invitationHTML.map((e) => <p key={e} dangerouslySetInnerHTML={{ __html: e }} />)
            : dbData.invitation.map((e) => (
                <p key={e}>
                  {e.split('\n').map((line) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))}
                </p>
              ))}
          {dbData.invitationSmall &&
            dbData.invitationSmall.map((e) => (
              <p key={e} className={styles.small}>
                {e.split('\n').map((line) => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </p>
            ))}
        </div>
      </Fade>
      <div
        className={styles.profile}
        style={{ display: noFooter ? 'none' : 'grid', borderColor: `rgba(${dbData.colors.deep}, 1)` }}
      >
        <Fade bottom duration={1400}>
          <div>
            <div className={styles.parents}>
              <span>
                <span
                  className={`${groomDadInfo.chrys === 'chrysIlust' ? styles.chrysIlust : ''} ${groomDadInfo.chrys === 'chrysIcon' ? styles.chrysIcon : ''}`}
                >
                  {!groomDadInfo.chrys?.includes('chrys') && groomDadInfo.chrys}
                </span>
                {groomDadInfo.name}
              </span>
              {Object.keys(groomDadInfo).length > 0 && Object.keys(groomMomInfo).length > 0 && <span> · </span>}
              <span>
                <span
                  className={`${groomMomInfo.chrys === 'chrysIlust' && styles.chrysIlust} ${groomMomInfo.chrys === 'chrysIcon' && styles.chrysIcon}`}
                >
                  {!groomMomInfo.chrys?.includes('chrys') && groomMomInfo.chrys}
                </span>
                {groomMomInfo.name}
              </span>
            </div>
            <span>의 {groom.find((e) => e.role === 'me')?.position}</span>
            {enter && <br />}
            <label>{groom.find((e) => e.role === 'me')?.name}</label>
          </div>
        </Fade>
        <Fade bottom duration={1600}>
          <div>
            <div className={styles.parents}>
              <span>
                {brideDadInfo.chrys}
                {brideDadInfo.name}
              </span>
              {Object.keys(brideDadInfo).length > 0 && Object.keys(brideMomInfo).length > 0 && <span> · </span>}
              <span>
                {brideMomInfo.chrys}
                {brideMomInfo.name}
              </span>
            </div>
            <span>의 {bride.find((e) => e.role === 'me')?.position}</span>
            {enter && <br />}
            <label>{bride.find((e) => e.role === 'me')?.name}</label>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Invitation;
