export const roleToKorean = {
  me: '본인',
  dad: '아버지',
  mom: '어머니',
  mom_grandfather: '외조부',
  mom_grandmother: '외조모',
  dad_grandfather: '친조부',
  dad_grandmother: '친조모',
  mom_sister: '이모',
  mom_sister_spouse: '이모부',
  dad_sister: '고모',
  dad_sister_spouse: '고모부',
  mom_brother: '외삼촌',
  mom_brother_spouse: '외숙모',
  dad_older_brother: '큰아버지',
  dad_older_brother_spouse: '큰어머니',
  dad_younger_brother: '작은아버지',
  dad_younger_brother_spouse: '작은어머니',
};
