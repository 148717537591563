import React, { useEffect, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import styles from './Music.module.css';

const Music = ({ dbData }) => {
  const src = `${process.env.PUBLIC_URL}/images/gallery/${dbData.alias}/${dbData.music}`;
  const playerRef = useRef();
  const [wasPlaying, setWasPlaying] = useState(false);

  useEffect(() => {
    const events = ['click', 'touchstart', 'keydown', 'pointerdown'];

    const handleInteraction = async () => {
      if (!playerRef.current?.audio?.current) return;

      try {
        await playerRef.current.audio.current.play();
      } catch (error) {
        console.error('자동 재생 실패:', error);
      }
    };

    events.forEach((event) => document.addEventListener(event, handleInteraction, { once: true }));

    return () => events.forEach((event) => document.removeEventListener(event, handleInteraction));
  }, []);

  return (
    <div className={styles.Music} style={{ color: `rgb(${dbData.colors.deep})` }}>
      <AudioPlayer
        src={src}
        ref={playerRef}
        loop
        playsInline
        preload='metadata'
        showSkipControls={false}
        showJumpControls={false}
        customControlsSection={['MAIN_CONTROLS', 'VOLUME']}
        customProgressBarSection={['PROGRESS_BAR']}
        onPlay={() => setWasPlaying(true)}
        onPause={() => setWasPlaying(false)}
        onSeeked={() => {
          if (wasPlaying && playerRef.current?.audio?.current) {
            playerRef.current.audio.current.play().catch((err) => {
              console.error('재생 실패:', err);
            });
          }
        }}
      />
    </div>
  );
};

export default Music;
