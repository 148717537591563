const rgbToHsv = (r, g, b) => {
  const max = Math.max(r, g, b) / 255;
  const min = Math.min(r, g, b) / 255;
  const delta = max - min;

  const h =
    delta === 0
      ? 0
      : max === r / 255
        ? (60 * ((g / 255 - b / 255) / delta) + 360) % 360
        : max === g / 255
          ? 60 * ((b / 255 - r / 255) / delta) + 120
          : 60 * ((r / 255 - g / 255) / delta) + 240;

  const s = max === 0 ? 0 : delta / max;
  return [h, s, max];
};

const hsvToRgb = (h, s, v) => {
  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  const [r, g, b] =
    h < 60
      ? [c, x, 0]
      : h < 120
        ? [x, c, 0]
        : h < 180
          ? [0, c, x]
          : h < 240
            ? [0, x, c]
            : h < 300
              ? [x, 0, c]
              : [c, 0, x];

  return `rgb(${Math.round((r + m) * 255)}, ${Math.round((g + m) * 255)}, ${Math.round((b + m) * 255)}`;
};

export const darkenColor = ([r, g, b], factor, saturationBoost = 0.5) => {
  const [h, s, v] = rgbToHsv(r, g, b); // h, s, v는 재할당되지 않으므로 const로 선언

  // 밝기를 줄이고, 채도를 약간 증가
  const newV = Math.max(0, v * factor); // Value 줄이기
  const newS = Math.min(1, s + saturationBoost); // Saturation 증가 (최대 1)

  return hsvToRgb(h, newS, newV);
};

export const rgbToHex = (r, g, b) => {
  return `#${((1 << 24) + (+r << 16) + (+g << 8) + b / 1).toString(16).slice(1)}`;
};

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};
