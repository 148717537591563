import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { useParams } from 'react-router-dom';

import useModal from '../../../../hooks/useModal';
import styles from './Gallery.module.css';
import Picture from './Picture';
import previous from '../../../../assets/icon/previous.svg';

const Gallery = ({ dbData }) => {
  const alias = useParams().id;

  const { gallery } = dbData;
  const numPics = gallery.length;

  const { isModalVisible, openModal, closeModal } = useModal();

  const [selectedPictureIdx, setSelectedPictureIdx] = useState(0);

  const handlePictureClick = ($idx) => {
    setSelectedPictureIdx($idx);
  };

  const getInfiniteIndex = ($increment) => {
    let newIndex = selectedPictureIdx + $increment;
    if (newIndex < 0) {
      newIndex = numPics - 1;
    } else if (newIndex >= numPics) {
      newIndex = 0;
    }

    return newIndex;
  };

  const handleButtonClick = ($increment) => {
    setSelectedPictureIdx(getInfiniteIndex($increment));
  };

  return (
    <>
      <div className={styles.Gallery}>
        <Fade bottom duration={1200}>
          <div className={styles.current_image}>
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${gallery[selectedPictureIdx].file})`,
                backgroundPosition: gallery[selectedPictureIdx].pos
                  ? `${gallery[selectedPictureIdx].pos[0]}% ${gallery[selectedPictureIdx].pos[1]}%`
                  : null,
              }}
              onClick={openModal}
            />
          </div>
        </Fade>

        <Fade bottom duration={1200}>
          <div className={styles.thumbnail_section}>
            <div className={styles.button} onClick={() => handleButtonClick(-1)}>
              <img src={previous} alt='previous' />
            </div>
            <div className={`${styles.thumbnails} ${styles[numPics < 5 ? 'three' : 'five']}`}>
              {Array.from({ length: numPics < 5 ? 3 : 5 }).map((_, idx) => {
                const thumbnailIndex = (selectedPictureIdx + idx - 2 + numPics) % numPics;
                return (
                  <div
                    key={gallery[thumbnailIndex].id}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${gallery[thumbnailIndex].file})`,
                      backgroundPosition: gallery[thumbnailIndex].pos
                        ? `${gallery[thumbnailIndex].pos[0]}% ${gallery[thumbnailIndex].pos[1]}%`
                        : null,
                      ...(thumbnailIndex === selectedPictureIdx && { outlineColor: `rgba(${dbData.colors.deep}, 1)` }),
                    }}
                    className={`${styles.thumbnail} ${thumbnailIndex === selectedPictureIdx ? styles.current_thumbnail : ''}`}
                    onClick={() => handlePictureClick(thumbnailIndex)}
                  />
                );
              })}
            </div>

            <div className={styles.button} onClick={() => handleButtonClick(1)}>
              <img src={previous} alt='next' style={{ transform: 'scaleX(-1)' }} />
            </div>
          </div>
        </Fade>
      </div>
      {isModalVisible && <Picture closeModal={closeModal} gallery={gallery} initialPictureIdx={selectedPictureIdx} />}
    </>
  );
};

export default Gallery;
