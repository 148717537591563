import React from 'react';

import { darkenColor } from '../../../../utils/colors';
import styles from './Footer.module.css';
import Share from '../ShareDefault';
import FooterTextDefault from '../FooterTextDefault';

const Footer = ({ dbData, backgroundColor }) => {
  return (
    <div
      className={styles.Footer}
      style={{
        backgroundColor: backgroundColor ? `rgb(${backgroundColor.join(', ')})` : `rgba(${dbData.colors.point}, 0.2)`,
        borderColor: backgroundColor ? darkenColor(backgroundColor, 1) : `rgba(${dbData.colors.point}, 1)`,
        color: backgroundColor ? darkenColor(backgroundColor, 0.5) : `rgba(${dbData.colors.point}, 1)`,
      }}
    >
      <Share styles={styles} dbData={dbData} />
      <FooterTextDefault />
    </div>
  );
};

export default Footer;
