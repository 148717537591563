import React from 'react';

import useSettingReducer from '../../../hooks/useSettingReducer';
import { hexToRgb, rgbToHex } from '../../../utils/colors';

const SetColors = () => {
  const { colorData, setColorData } = useSettingReducer();

  const colorTemplates = {
    1: { main: '248, 242, 222', light: '252, 250, 243', deep: '244, 219, 187', point: '140, 180, 160' },
    2: { deep: '122, 159, 108', light: '242, 245, 236', main: '222, 213, 203', point: '202, 193, 183' },
    3: { deep: '119, 152, 200', light: '245, 249, 252', main: '220, 232, 239', point: '204, 204, 204' },
  };
  const setColors = (e) => {
    const num = e.target.innerHTML;
    setColorData(colorTemplates[num]);
  };

  return (
    <>
      <div>
        <p>색상 템플릿</p>
        <button style={{ backgroundColor: `rgb(248, 242, 222)` }} onClick={setColors}>
          1
        </button>
        <button style={{ backgroundColor: `rgb(222, 213, 203)` }} onClick={setColors}>
          2
        </button>
        <button style={{ backgroundColor: `rgb(220, 232, 239)` }} onClick={setColors}>
          3
        </button>
      </div>

      {/* Colors */}
      <div>
        <div>
          <label>기본 색상</label>
          <input
            id='colorBoxMain'
            type='color'
            defaultValue={rgbToHex(...colorData.main.split(','))}
            value={rgbToHex(...colorData.main.split(','))}
            onChange={(e) => setColorData({ ...colorData, main: hexToRgb(e.target.value) })}
          />
          <p>RGB({colorData.main})</p>
        </div>
        <div>
          <label>밝은 색상</label>
          <input
            id='colorBoxLight'
            type='color'
            defaultValue={rgbToHex(...colorData.light.split(','))}
            value={rgbToHex(...colorData.light.split(','))}
            onChange={(e) => setColorData({ ...colorData, light: hexToRgb(e.target.value) })}
          />
          <p>RGB({colorData.light})</p>
        </div>
        <div>
          <label>어두운 색상</label>
          <input
            id='colorBoxDeep'
            type='color'
            defaultValue={rgbToHex(...colorData.deep.split(','))}
            value={rgbToHex(...colorData.deep.split(','))}
            onChange={(e) => setColorData({ ...colorData, deep: hexToRgb(e.target.value) })}
          />
          <p>RGB({colorData.deep})</p>
        </div>
        <div>
          <label>강조 색상</label>
          <input
            id='colorBoxPoint'
            type='color'
            defaultValue={rgbToHex(...colorData.point.split(','))}
            value={rgbToHex(...colorData.point.split(','))}
            onChange={(e) => setColorData({ ...colorData, point: hexToRgb(e.target.value) })}
          />
          <p>RGB({colorData.point})</p>
        </div>
      </div>
    </>
  );
};

export default SetColors;
