import React, { Suspense } from 'react';

import styles from './PageList.module.css';
import Loading from '../Common/Loading/Loading';

const PageList = () => {
  const invationAliasList = [
    '2412305724985cn',
    '2412305724985en',
    '2502155664255',
    '2503295924798',
    '2504276059545',
    '2505036120093',
    '2505045724985',
    '2505116046440',
    '5724985',
    'template03',
    'template_color_preview',
    'template_simple_1',
    'template_simple_2',
    'template_simple_3',
    'template_test',
  ];

  return (
    <Suspense fallback={<Loading />}>
      <div>
        <div className={styles.PageList}>
          <h1>Localhost</h1>
          {invationAliasList.map((e) => (
            <li>
              <a href={`./wedding/${e}`}>{e}</a>
            </li>
          ))}
          <hr />
          <h1>Live Server</h1>
          {invationAliasList.map((e) => (
            <li>
              <a href={`https://happy-ever-after.site/wedding/${e}`}>{e}</a>
            </li>
          ))}
        </div>
      </div>
    </Suspense>
  );
};

export default PageList;
