import React from 'react';

const FooterTextDefault = () => {
  const handleClick = ($url) => {
    window.open($url);
  };

  return (
    <>
      <p>
        kmong “
        <span onClick={() => handleClick('https://kmong.com/gig/555164')}>
          내 취향에 꼭 맞추는 모바일 청첩장 주문제작
        </span>
        ”
      </p>
      <p>{`Copyright © ${new Date().getFullYear()} Malg-Eum, Yu. All rights reserved.`}</p>
    </>
  );
};

export default FooterTextDefault;
