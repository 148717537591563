import { createSlice } from '@reduxjs/toolkit';

import { initialSettingComponents } from './initialSettingComponents';

const settingComponentSlice = createSlice({
  name: 'settingComponents',
  initialState: initialSettingComponents,
  reducers: {
    setSettingComponentData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSettingComponentData } = settingComponentSlice.actions;

export default settingComponentSlice.reducer;
