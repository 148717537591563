import React from 'react';

import useSettingReducer from '../../../hooks/useSettingReducer';

const SetInfo = ({ styles }) => {
  const { weddingData, setWeddingData } = useSettingReducer();

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const [year, month, day] = inputDate.split('-');
    const newDate = { ...weddingData.weddingDate, year, month, day };
    setWeddingData({ ...weddingData, weddingDate: newDate });
  };

  const handleTimeChange = (e) => {
    const inputTime = e.target.value;
    const newDate = { ...weddingData.weddingDate, time: inputTime };
    setWeddingData({ ...weddingData, weddingDate: newDate });
  };

  return (
    <div className={styles.SetInfo}>
      <div>
        <h4>예식일</h4>
        <input type='date' onChange={handleDateChange} />
        <input type='time' onChange={handleTimeChange} />
      </div>

      <div>
        <h4>예식장 위치</h4>으 주소검색기
      </div>
    </div>
  );
};

export default SetInfo;
