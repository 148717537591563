export const initialSettingComponents = {
  header: 'template_color_preview',
  title: 'template_color_preview',
  banking: 'template_color_preview',
  calendar: 'template_color_preview',
  dday: 'template_simple_1',
  contact: 'template_color_preview',
  gallery: 'template_color_preview',
  invitation: 'template_color_preview',
  map: 'template_color_preview',
};
