import { useDispatch, useSelector } from 'react-redux';

const useSettingReducer = () => {
  const colorData = useSelector((state) => state.colors); // Redux 상태 읽기
  const weddingData = useSelector((state) => state.wedding); // Redux 상태 읽기
  const settingComponentData = useSelector((state) => state.settingComponents); // Redux 상태 읽기

  const dispatch = useDispatch();
  const setColorData = (newColors) => {
    dispatch({ type: 'colors/setColorData', payload: newColors }); // Redux 상태 변경
  };
  const setWeddingData = (newData) => {
    dispatch({ type: 'wedding/setWeddingData', payload: newData }); // Redux 상태 변경
  };
  const setSettingComponentData = (newData) => {
    dispatch({ type: 'settingComponents/setSettingComponentData', payload: newData });
  };

  return {
    colorData,
    setColorData,
    weddingData,
    setWeddingData,
    settingComponentData,
    setSettingComponentData,
  };
};

export default useSettingReducer;
