import React, { useState, useEffect } from 'react';
import { Fade } from 'react-reveal';

import styles from './Calendar.module.css';
import { generateCalendar, week, weekCN, weekEN } from '../../../../utils/generateCalendar';

const Calendar = ({ dbData, language, notice = '' }) => {
  const { year, month, day, time } = dbData.weddingDate;
  const [calendarData, setCalendarData] = useState([]);

  const weekArray = language === 'en' ? weekEN : language === 'cn' ? weekEN : week;
  const add = ['恭候', '入席'];

  useEffect(() => {
    setCalendarData(generateCalendar(year, month));
  }, [year, month]);

  // Safari에서도 호환되는 ISO 8601 형식의 문자열로 Date 객체 생성
  const weddingDate = new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${time}`);

  const formattedDate = (type, target = 'weddingDate') => {
    let thisDate;

    if (target instanceof Date) {
      thisDate = target;
    } else {
      const { year: thisYear, month: thisMonth, day: thisDay, time: thisTime } = dbData[target];
      thisDate = new Date(
        `${thisYear}-${String(thisMonth).padStart(2, '0')}-${String(thisDay).padStart(2, '0')}T${thisTime}`,
      );
    }

    const thisDay = thisDate.getDate();
    const thisMonth = thisDate.getMonth() + 1;
    const thisYear = thisDate.getFullYear();

    const enDate = `${thisDay}. ${thisMonth}. ${thisYear}. (${weekArray[thisDate.getDay()]})`;
    const enTime = `${thisDate.toLocaleTimeString('en', {
      hour: 'numeric',
      minute: 'numeric',
    })}`;

    const koDate = `${thisYear}. ${thisMonth}. ${thisDay}. (${weekArray[thisDate.getDay()]})`;
    const koTime = `${thisDate.toLocaleTimeString().split(':')[0]}시 ${
      thisDate.toLocaleTimeString().split(':')[1] !== '00' ? `${thisDate.toLocaleTimeString().split(':')[1]}분` : ''
    }`;

    const cnDate = `${thisDay}. ${thisMonth}. ${thisYear}. (${weekCN[thisDate.getDay()]})`;
    const cnTime = `${
      thisDate
        .toLocaleTimeString('chi', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
        .split(':')[0]
    }時 ${
      thisDate
        .toLocaleTimeString('chi', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
        .split(':')[1] !== '00'
        ? `${
            thisDate
              .toLocaleTimeString('chi', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
              .split(':')[1]
          }分`
        : ''
    }`;

    if (language === 'en') {
      switch (type) {
        case 'date':
          return enDate;
        case 'time':
          return enTime;
        case 'datetime':
          return enDate + enTime;
        default:
          break;
      }
    }

    if (language === 'cn') {
      switch (type) {
        case 'date':
          return cnDate;
        case 'time':
          return `${cnTime.slice(0, 2)} ${cnTime.slice(2)}`;
        case 'datetime':
          return `${cnDate} ${cnTime.slice(0, 2)} ${cnTime.slice(2)}`;
        default:
          break;
      }
    }

    switch (type) {
      case 'date':
        return koDate;
      case 'time':
        return koTime;
      case 'datetime':
        return koDate + koTime;
      default:
    }
  };

  return (
    <div className={styles.calendar_wrapper} style={{ background: `rgb(${dbData.colors.light})` }}>
      <Fade bottom duration={1000}>
        <h3>{formattedDate('date')}</h3>
        <div className={styles.time}>D-0</div>
      </Fade>
      <div id='Calendar' className={styles.Calendar}>
        <p style={{ fontFamily: language === 'cn' ? 'SourceHanSerifCN' : '' }}>
          {weddingDate.toLocaleDateString(language === 'en' ? 'en' : language === 'cn' ? 'chi' : 'ko', {
            month: 'long',
          })}
        </p>
        <table>
          <Fade bottom duration={1200}>
            <thead>
              <tr>
                <th>{weekArray[0]}</th>
                <th>{weekArray[1]}</th>
                <th>{weekArray[2]}</th>
                <th>{weekArray[3]}</th>
                <th>{weekArray[4]}</th>
                <th>{weekArray[5]}</th>
                <th>{weekArray[6]}</th>
              </tr>
            </thead>
          </Fade>
          <tbody>
            {calendarData.map((thisWeek, i) => (
              <tr>
                <Fade bottom duration={1400 + i * 100}>
                  {thisWeek.map((dayOfCalendar) =>
                    dayOfCalendar.day ? (
                      <td key={dayOfCalendar.day}>
                        <div
                          style={{
                            ...(new Date(year, month - 1, dayOfCalendar.day).toDateString() ===
                              new Date(
                                `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
                              ).toDateString() && {
                              backgroundColor: `rgba(${dbData.colors.deep})`,
                              fontFamily: 'Song Myung',
                              fontSize: '1.2rem',
                              fontWeight: 'bold',
                              color: 'white',
                            }),
                          }}
                        >
                          {dayOfCalendar.day}
                        </div>
                      </td>
                    ) : (
                      <td key={new Date().getTime() - Math.random() * 1000} />
                    ),
                  )}
                </Fade>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
