import React from 'react';
import { Fade } from 'react-reveal';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import Music from '../../../UtilComponents/Music/template_simple_1/Music';
import YouTubePlayer from '../../../../hooks/useYouTubePlayer.js';
import styles from './Title.module.css';
import diagonal from '../../../../assets/deco/diagonal.svg';
import downArrow from '../../../../assets/icon/down-arrow.svg';
import useTitleScrollHeight from '../../../../hooks/useTitleScrollHeight';
import { week } from '../../../../utils/generateCalendar.js';

const Title = ({ dbData, backgroundPositionY, groomFirst, customClass, customBottom, topFlexDirection }) => {
  const alias = useParams().id;

  const { bride, groom } = dbData.familyInfo;
  const { detailAddress } = dbData.locationInfo;
  const { venue } = dbData.locationInfo;

  const { year, month, day, time } = dbData.weddingDate;
  // Safari에서도 호환되는 ISO 8601 형식의 문자열로 Date 객체 생성
  const weddingDate = new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${time}`);

  const scrollPosForJump = useTitleScrollHeight();
  const JumpFromTitle = () => {
    window.scrollTo({
      top: scrollPosForJump,
      behavior: 'smooth',
    });
  };

  const windowHeight = Math.min(window.outerHeight, window.innerHeight);

  return (
    <div
      className={styles.Title}
      style={{
        height: windowHeight,
        minHeight: windowHeight,
      }}
    >
      <Fade bottom duration={1000}>
        <div className={styles.top}>
          <img src={diagonal} alt='diagonal left' />
          <span>{dbData.titleTopText}</span>
          <img src={diagonal} alt='diagonal right' />
        </div>
      </Fade>
      <Fade bottom duration={1200}>
        <div className={styles.couple} style={{ flexDirection: topFlexDirection || 'column' }}>
          <span>
            {groomFirst ? groom.find((e) => e.role === 'me')?.name : bride.find((e) => e.role === 'me')?.name}
          </span>
          <p>and</p>
          <span>
            {groomFirst ? bride.find((e) => e.role === 'me')?.name : groom.find((e) => e.role === 'me')?.name}
          </span>
        </div>
      </Fade>

      <Fade duration={1400}>
        <link
          rel='preload'
          as='image'
          href={`${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${dbData.backgrounds?.title}`}
        />
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${dbData.backgrounds?.title})`,
            backgroundPositionY: backgroundPositionY || '40%',
          }}
        >
          {dbData.backgrounds.videoId && <YouTubePlayer videoId={dbData.backgrounds.videoId} />}
        </div>
      </Fade>

      <Fade bottom duration={1600}>
        <div className={`${styles.address} ${customClass ? styles[customClass] : ''}`}>
          {customBottom || (
            <div>
              <p className={styles.date}>
                {year}년 {month}월 {day}일 {week[weddingDate.getDay()]}요일,{' '}
                {weddingDate.toLocaleTimeString().split(':')[0]}시{' '}
                {weddingDate.toLocaleTimeString().split(':')[1] !== '00'
                  ? `${weddingDate.toLocaleTimeString().split(':')[1]}분`
                  : null}
              </p>
              <p className={styles.location}>
                {detailAddress} {venue}
              </p>
            </div>
          )}

          {dbData.music && <Music dbData={dbData} />}
        </div>
      </Fade>
      <Fade duration={2000}>
        <div className={styles.scrolldown} onClick={JumpFromTitle}>
          <motion.div
            animate={{ y: [0, 10, 0] }}
            transition={{
              type: 'tween',
              duration: 1,
              repeat: Infinity,
              repeatDelay: 1,
            }}
          >
            <img src={downArrow} alt='down arrow' />
          </motion.div>
        </div>
      </Fade>
    </div>
  );
};

export default Title;
