import React, { useEffect, useState, useRef } from 'react';
import { Fade } from 'react-reveal';
import { useParams } from 'react-router-dom';

import styles from './Gallery.module.css';
import useModal from '../../../../hooks/useModal';

const Picture = ({ closeModal, gallery, initialPictureIdx }) => {
  const alias = useParams().id;

  const { handleContentClick } = useModal();
  const [currentIdx, setCurrentIdx] = useState(initialPictureIdx);
  const [picture, setPicture] = useState({
    id: '',
    file: '',
    comment: '',
  });
  const commentRef = useRef(null);

  useEffect(() => {
    setPicture(gallery[currentIdx]);
  }, [currentIdx]);

  useEffect(() => {
    for (let i = 0; i < gallery.length; i++) {
      const preloadImage = new Image();
      preloadImage.src = `${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${gallery[i].file}`;
    }
  }, [gallery]);

  const handleMouseEnter = () => {
    if (picture.comment) {
      commentRef.current.classList.remove('hide');
      commentRef.current.classList.add('show');
    }
  };

  const handleMouseLeave = () => {
    if (picture.comment) {
      commentRef.current.classList.remove('show');
      commentRef.current.classList.add('hide');
    }
  };

  return (
    <div className='Modal gallery' onClick={closeModal}>
      <div className={styles.picture_wrapper} onClick={handleContentClick}>
        <Fade key={picture.file} duration={500}>
          <div className='modal_wrapper'>
            <div className={styles.Picture}>
              <img
                src={`${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${picture.file}`}
                alt={picture.file}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {picture.comment && (
                <p className='hide' ref={commentRef}>
                  {picture.comment}
                </p>
              )}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Picture;
