import React from 'react';

import styles from './Designer.module.css';
import Settings from './Settings';
import Preview from './Preview';

const Designer = () => {
  return (
    <div className={styles.Designer}>
      <Settings />
      <Preview />
    </div>
  );
};

export default Designer;
