import React from 'react';

import useSettingReducer from '../../../hooks/useSettingReducer';

const SetFamily = ({ styles }) => {
  const { weddingData, setWeddingData } = useSettingReducer();

  const handleNameChange = (e, group, role) => {
    const inputName = e.target.value;

    // 기존 데이터 복사
    const updatedFamily = {
      ...weddingData.familyInfo,
      [group]: weddingData.familyInfo[group].map((member) =>
        member.role === role ? { ...member, name: inputName } : member,
      ),
    };

    // Redux 상태 업데이트
    setWeddingData({
      ...weddingData,
      familyInfo: updatedFamily,
    });
  };

  return (
    <div className={styles.SetFamily}>
      <div>
        <h5>신랑</h5>
        <div className='input_wrapper'>
          <label>본인</label>
          <input type='text' placeholder='성함' onBlur={(e) => handleNameChange(e, 'groom', 'me')} />
          <input type='text' placeholder='호칭' />
        </div>

        <div className='input_wrapper'>
          <label>
            <select defaultValue='dad'>
              <option value=''>-관계-</option>
              <option value='dad' selected>
                아버지
              </option>
              <option value='mom'>어머니</option>
            </select>
          </label>
          <input type='text' placeholder='성함' />
        </div>

        <div className='input_wrapper'>
          <label>
            <select defaultValue='mom'>
              <option value=''>-관계-</option>
              <option value='dad'>아버지</option>
              <option value='mom' selected>
                어머니
              </option>
            </select>
          </label>
          <input type='text' placeholder='성함' />
        </div>
      </div>
      <div>
        <h5>신부</h5>
        <div className='input_wrapper'>
          <label>본인</label>
          <input type='text' placeholder='성함' />
          <input type='text' placeholder='호칭' />
        </div>

        <div className='input_wrapper'>
          <label>
            <select defaultValue='dad'>
              <option value=''>-관계-</option>
              <option value='dad' selected>
                아버지
              </option>
              <option value='mom'>어머니</option>
            </select>
          </label>
          <input type='text' placeholder='성함' />
        </div>

        <div className='input_wrapper'>
          <label>
            <select defaultValue='mom'>
              <option value=''>-관계-</option>
              <option value='dad'>아버지</option>
              <option value='mom' selected>
                어머니
              </option>
            </select>
          </label>
          <input type='text' placeholder='성함' />
        </div>
      </div>
    </div>
  );
};

export default SetFamily;
