export const generateCalendar = (year, month) => {
  const currentDate = new Date(year, month - 1, 1); // start date

  const calendar = [];
  let weekArr = [];

  // 첫 주가 7일이 아닌 경우 빈 셀 채우기
  for (let i = 0; i < currentDate.getDay(); i++) {
    weekArr.push({ day: null });
  }

  while (currentDate.getMonth() === month - 1) {
    weekArr.push({
      day: currentDate.getDate(),
    });

    if (currentDate.getDay() === 6) {
      // 토요일(=6) 이 되면 다음 주차 시작
      calendar.push(weekArr);
      weekArr = [];
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  // 마지막 주가 7일이 아닌 경우 빈 셀 채우기
  if (weekArr.length > 0) {
    for (let i = weekArr.length; i < 7; i++) {
      weekArr.push({ day: null });
    }
    calendar.push(weekArr);
  }

  return calendar;
};

export const week = ['일', '월', '화', '수', '목', '금', '토'];
export const weekEN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const weekCN = ['日', '一', '二', '三', '四', '五', '六'];
