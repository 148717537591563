import React from 'react';

import useSettingReducer from '../../../hooks/useSettingReducer';

const SetCalendar = ({ styles }) => {
  const { setSettingComponentData, settingComponentData } = useSettingReducer();

  const handleCalendarSelect = (e) => {
    const newData = { ...settingComponentData, calendar: e.target.value };
    setSettingComponentData(newData);
  };

  const handleDdaySelect = (e) => {
    const newData = { ...settingComponentData, dday: e.target.value };
    setSettingComponentData(newData);
  };

  return (
    <div className={styles.SetCalendar}>
      <label>달력</label>
      <select onChange={handleCalendarSelect}>
        <option value={settingComponentData.calendar}>---디자인 선택---</option>
        <option value='template_simple_2'>Simple 2</option>
        <option value='template_simple_3'>Simple 3</option>
        <option value='template_simple_4'>Simple 4</option>
      </select>
      <label>디데이</label>
      <select onChange={handleDdaySelect}>
        <option value={settingComponentData.dday}>---디자인 선택---</option>
        <option value='template_simple_1'>Simple 2</option>
        <option value='template_simple_3'>Simple 3</option>
      </select>
    </div>
  );
};

export default SetCalendar;
