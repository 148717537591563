import React, { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { useSelector } from 'react-redux';

import useModal from '../../../../hooks/useModal';

const Menus = ({ dbData, styles, closeModal }) => {
  const colorData = useSelector((state) => state.colors); // Redux 상태 구독

  const { handleContentClick } = useModal();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const h1Elements = document.querySelectorAll('h1.menu');

    // h1 요소들을 배열로 변환하여 state에 저장
    const extractedMenuItems = Array.from(h1Elements).map((element) => ({
      title: element.textContent,
      sectionId: element.closest('section').id,
    }));

    setMenuItems(extractedMenuItems);

    // 컴포넌트 언마운트 시 클리어 업 함수
    return () => {
      setMenuItems([]);
    };
  }, []);

  const handleMenuClick = (sectionId) => {
    const targetElement = document.querySelector(`#${sectionId}`);

    if (targetElement) {
      let scrollPos = (targetElement.offsetTop || 1000) - window.innerHeight / 10;
      if (sectionId === 'title') scrollPos = 0;
      if (sectionId === 'invitation') scrollPos = window.innerHeight;

      window.scrollTo({
        top: scrollPos,
        behavior: 'smooth',
      });
    }

    closeModal();
  };

  return (
    <div className='Modal menu' onClick={closeModal}>
      <div className='modal_wrapper' onClick={handleContentClick}>
        <Fade>
          <div className={`${styles.Menus}`} style={{ backgroundColor: `rgba(${colorData.main}, 0.5)` }}>
            {menuItems.map((menuItem) => (
              <div key={menuItem.sectionId} onClick={() => handleMenuClick(menuItem.sectionId)}>
                <span>{menuItem.title}</span>
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Menus;
