import React, { useState, useEffect } from 'react';
import { Fade } from 'react-reveal';

import styles from './Calendar.module.css';
import { generateCalendar } from '../../../../utils/generateCalendar';

const Calendar = ({ dbData, banquet, style, customClass, customTitle, theme = 'light' }) => {
  let year;
  let month;
  let day;

  if (banquet) {
    ({ year, month, day } = dbData.banquetDate);
  } else {
    ({ year, month, day } = dbData.weddingDate);
  }

  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    setCalendarData(generateCalendar(year, month));
  }, [year, month]);

  return (
    <div className={`${styles.calendar_wrapper} ${customClass ? styles[customClass] : ''}`} style={style}>
      <Fade bottom duration={1200}>
        <h2>{customTitle || `${year}년 ${month}월`}</h2>
      </Fade>
      <div id='Calendar' className={`${styles.Calendar} ${theme === 'dark' && styles.darkTheme}`}>
        <table>
          <Fade bottom duration={1400}>
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  일
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  월
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  화
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  수
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  목
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point}, 1)`,
                  }}
                >
                  금
                </th>
                <th
                  style={{
                    backgroundColor: `rgba(${dbData.colors.point}, 1)`,
                    borderColor: `rgba(${dbData.colors.point},1)`,
                  }}
                >
                  토
                </th>
              </tr>
            </thead>
          </Fade>
          <tbody>
            {calendarData.map((week, i) => (
              <tr>
                <Fade bottom duration={1600 + i * 100}>
                  {week.map((dayOfCalendar) =>
                    dayOfCalendar.day ? (
                      <td
                        key={dayOfCalendar.day}
                        style={{
                          ...(new Date(year, month - 1, dayOfCalendar.day).toDateString() ===
                            new Date(
                              `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
                            ).toDateString() && {
                            border: `2px solid rgba(${dbData.colors.point}, 0.75)`,
                            backgroundColor: `rgba(${dbData.colors.main}, 0.5)`,
                            fontWeight: 'bold',
                            color: theme === 'dark' ? '' : `rgba(${dbData.colors.point}, 1)`,
                          }),
                        }}
                      >
                        {dayOfCalendar.day}
                      </td>
                    ) : (
                      <td key={new Date().getTime() - Math.random() * 1000} />
                    ),
                  )}
                </Fade>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
