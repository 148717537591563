import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useScrollPosition from '../../../../hooks/useScrollPosition';
import useTitleScrollHeight from '../../../../hooks/useTitleScrollHeight';
import useModal from '../../../../hooks/useModal';
import styles from './Header.module.css';
import Menus from './Menus';

const Header = ({ dbData }) => {
  const colorData = useSelector((state) => state.colors); // Redux 상태 구독

  const { bride, groom } = dbData.familyInfo;

  const { isModalVisible, openModal, closeModal } = useModal();

  const scrollPosition = useScrollPosition();
  const [maxScroll, setMaxScroll] = useState(0);
  const scrollPosForJump = useTitleScrollHeight();

  const throttle = (func, limit) => {
    let inThrottle;
    return (...args) => {
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => {
          inThrottle = false;
        }, limit);
      }
    };
  };

  const handleScroll = () => {
    if (scrollPosition === 0 && window.scrollY > 0) {
      window.scrollTo({
        top: scrollPosForJump,
        behavior: 'smooth',
      });
    } else if (scrollPosition >= scrollPosForJump && window.scrollY < scrollPosForJump) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScrollThrottled = throttle(handleScroll, 500);

    window.addEventListener('scroll', handleScrollThrottled);

    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [scrollPosition]);

  useEffect(() => {
    setMaxScroll(document.documentElement.scrollHeight - window.innerHeight);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const handleMenuClick = () => {
    if (isModalVisible) {
      closeModal();
    } else {
      openModal();
    }
  };

  return (
    <>
      <div className={`${styles.Header} ${scrollPosition === 0 ? 'hide' : ''}`}>
        <div
          className={styles.progress}
          style={{
            background: `linear-gradient(to right, rgba(${colorData.deep}, 0.75)  ${(100 * scrollPosition) / maxScroll}%, rgba(255, 255, 255, 0.5) ${(100 * scrollPosition) / maxScroll}%)`,
          }}
        />
        <div>
          <span />
          <span>
            {dbData.headerText ||
              `${bride.find((e) => e.role === 'me')?.name}  ${groom.find((e) => e.role === 'me')?.name}`}
          </span>
          <div className={styles.menu} onClick={handleMenuClick}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='bi bi-three-dots-vertical'
              viewBox='0 0 16 16'
            >
              <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
            </svg>
          </div>
        </div>
      </div>
      {isModalVisible && <Menus dbData={dbData} styles={styles} closeModal={closeModal} />}
    </>
  );
};

export default Header;
