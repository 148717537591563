import { configureStore } from '@reduxjs/toolkit';

import weddingReducer from './weddingSlice';
import colorReducer from './colorSlice';
import settingComponentReducer from './settingComponentSlice';

export default configureStore({
  reducer: {
    wedding: weddingReducer,
    colors: colorReducer,
    settingComponents: settingComponentReducer,
  },
});
