import React, { useState } from 'react';

import styles from './Settings.module.css';
import SetColors from './SettingComponents/SetColors';
import SetTitle from './SettingComponents/SetTitle';
import SetCalendar from './SettingComponents/SetCalendar';
import SetInvitation from './SettingComponents/SetInvitation';
import SetInfo from './SettingComponents/SetInfo';
import SetFamily from './SettingComponents/SetFamily';

const Settings = () => {
  const compoNameObj = {
    title: <SetTitle styles={styles} />,
    invitation: <SetInvitation styles={styles} />,
    calendar: <SetCalendar styles={styles} />,
    map: 'SetMap',
    gallery: 'SetGallery',
    contact: 'SetContact',
    banking: 'SetBanking',
  };

  const [selectedCompo, setSelectedCompo] = useState();

  const handleSelect = (e) => {
    setSelectedCompo(compoNameObj[e.target.value]);
  };

  return (
    <div className={styles.Settings}>
      <details open>
        <summary>페이지 순서 변경/삭제</summary>
      </details>
      <hr />
      <details open>
        <summary>정보 설정</summary>

        <div>
          <h3>예식 정보 설정</h3>
          <SetInfo styles={styles} />
        </div>

        <div>
          <h3>가족 정보 설정</h3>
          <SetFamily styles={styles} />
        </div>

        <div>
          <h3>색상 테마 설정</h3>
          <SetColors styles={styles} />
        </div>
      </details>
      <hr />
      <details open>
        <summary>메뉴 디자인 설정</summary>
        <select onChange={handleSelect}>
          <option value=''>---페이지 선택---</option>
          <option value='title'>타이틀</option>
          <option value='invitation'>초대사</option>
          <option value='calendar'>달력</option>
          <option value='map'>오시는 길</option>
          <option value='gallery'>갤러리</option>
          <option value='contact'>연락처</option>
          <option value='banking'>마음 전하실 곳</option>
        </select>

        {selectedCompo}
      </details>
      <hr />
    </div>
  );
};

export default Settings;
