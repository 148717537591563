import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const weddingSlice = createSlice({
  name: 'wedding',
  initialState,
  reducers: {
    setWeddingData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setWeddingData } = weddingSlice.actions;

export default weddingSlice.reducer;
