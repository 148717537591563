import { useEffect, useRef } from 'react';

const YouTubePlayer = ({ videoId, width, height, autoplay = true }) => {
  const playerRef = useRef(null);

  const onPlayerReady = (event) => {
    event.target.mute(); // 유튜브 정책 상, 음소거를 설정하지 않으면 자동재생이 되지 않음
    event.target.playVideo();
  };

  useEffect(() => {
    function loadPlayer() {
      playerRef.current = new window.YT.Player('player', {
        height: height || '100%',
        width: width || '100%',
        videoId,
        controls: 0,
        events: {
          onReady: autoplay && onPlayerReady,
        },
      });
    }

    // YouTube IFrame API가 없을 때만 스크립트 추가
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.head.appendChild(tag);

      // API가 로드되면 player 초기화
      window.onYouTubeIframeAPIReady = loadPlayer;
    } else {
      // 이미 API가 로드된 경우 바로 player 생성
      loadPlayer();
    }

    return () => {
      // 컴포넌트가 언마운트될 때 player 정리
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]); // videoId가 변경될 때마다 player 재로드

  return <div id='player' />;
};

export default YouTubePlayer;
