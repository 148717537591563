import React from 'react';
import { Fade } from 'react-reveal';
import { useParams } from 'react-router-dom';

import styles from './Dday.module.css';

const Dday = ({ dbData }) => {
  const alias = useParams().id;

  const { day, month, time, year } = dbData.weddingDate;
  const date = new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${time}`);
  const ddayCount = Math.ceil((date.getTime() - new Date().getTime()) / (60 * 60 * 1000 * 24));

  return (
    <Fade bottom duration={2000}>
      <div className={styles.Dday}>
        <div
          className={styles.background}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/${alias.includes('template') ? 'template' : alias}/${dbData.backgrounds.dday})`,
          }}
        />
        <div className={styles.contents}>
          <div>
            <span>D</span>
            <span>{ddayCount < 0 ? '+' : ''}</span>
            <span>{ddayCount * -1}</span>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Dday;
