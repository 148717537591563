import React, { useEffect } from 'react';

import kakao from '../../../assets/icon/logo_kakao.svg';
import sms from '../../../assets/icon/sms.svg';
import link from '../../../assets/icon/link.svg';

const Share = ({ styles, dbData }) => {
  const currentUrl = window?.location.href;

  const data = {
    title: `${dbData.headerText}`,
    text: `${dbData.invitationSmall ? dbData.invitationSmall.join(' ') : dbData.invitation.join(' ')}`,
    url: currentUrl,
  };

  useEffect(() => {
    const script = document.createElement('script');

    if (typeof window !== 'undefined') {
      script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.6.0/kakao.min.js';
      script.integrity = 'sha384-6MFdIr0zOira1CHQkedUqJVql0YtcZA1P0nbPrQYJXVJZUkTk/oX4U9GhUIs3/z8';
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      script.onload = () => {
        window.Kakao.init(`${process.env.REACT_APP_KAKAO_DEVS_JS_KEY}`);
      };
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const linkShare = () => {
    if (navigator.share) {
      navigator.share(data).catch((error) => console.error('공유 실패', error));
    } else {
      navigator.clipboard.writeText(currentUrl);
      alert('URL을 복사하였습니다.');
    }
  };

  const kakaoShare = () => {
    window.Kakao.Share.sendScrap({
      requestUrl: currentUrl,
    });
  };

  const smsShare = () => {
    const smsText = `[${data.title}] ${data.text}
${currentUrl}`;

    navigator.clipboard
      .writeText(smsText)
      .then(() => {
        alert('공유 텍스트가 클립보드에 복사되었습니다. 원하는 곳에 붙여넣어 사용하세요.');
      })
      .catch((err) => {
        console.error('클립보드 복사 실패:', err);
        alert('공유 메시지 생성에 실패했습니다.');
      });
  };

  return (
    <div className={styles.Share}>
      <button type='button' onClick={kakaoShare} style={{ backgroundColor: `rgba(${dbData.colors.deep}, 0.5)` }}>
        <img src={kakao} id='kakaotalk-sharing-btn' alt='share with kakaotalk' />
      </button>
      <button type='button' onClick={smsShare} style={{ backgroundColor: `rgba(${dbData.colors.deep}, 0.5)` }}>
        <img src={sms} alt='share with SMS' />
      </button>
      <button type='button' onClick={linkShare} style={{ backgroundColor: `rgba(${dbData.colors.deep}, 0.5)` }}>
        <img src={link} alt='share with link' />
      </button>
    </div>
  );
};

export default Share;
