export const initialState = {
  backgrounds: {
    dday: 'dday.jpg',
    title: 'wedding_8.jpg',
  },
  familyInfo: {
    bride: [
      {
        accountNumber: '신한 123-4567-890',
        name: '이신부',
        phone: '010-0000-1111',
        role: 'me',
        position: '장녀',
      },
      {
        accountNumber: '카카오뱅크 012345-6-78',
        name: '이아빠',
        phone: '010-0000-3333',
        role: 'dad',
      },
      {
        accountNumber: '농협 01-1234-1234-1',
        name: '최엄마',
        phone: '010-0000-5555',
        role: 'mom',
      },
    ],
    groom: [
      {
        accountNumber: '국민 01-234-567890',
        name: '김신랑',
        phone: '010-0000-0000',
        role: 'me',
        position: '차남',
      },
      {
        accountNumber: '기업 12-34-4567-8901',
        name: '김아빠',
        phone: '010-0000-2222',
        role: 'dad',
      },
      {
        accountNumber: '신한 987-123-00000',
        name: '박엄마',
        phone: '010-0000-4444',
        role: 'mom',
      },
    ],
  },
  gallery: [
    {
      file: 'wedding_6.jpg',
    },
    {
      file: 'wedding_2.jpg',
    },
    {
      file: 'wedding_3.jpg',
    },
    {
      file: 'wedding_9.jpg',
    },
    {
      file: 'wedding_5.jpg',
    },
  ],
  headerText: '이신부 ♥ 김신랑',
  titleTopText: 'WEDDING INVITATION',
  invitation: [
    '예쁘게 만나오던',
    '저희 두 사람이',
    '12월의 한 좋은 날',
    '소중한 결혼식을 올리게 되었습니다.',
    '믿음과 사랑을 약속하는 자리에',
    '부디 참석하셔서 자리를 빛내주시길',
    '진심으로 희망합니다.',
  ],
  locationInfo: {
    coordinates: [37.5658043, 127.0165817],
    detailAddress: '샘플예식장 3층',
    jibunAddress: '서울 강남구 논현동 152',
    parkingInfo: '샘플예식장 지하주차장 사용 (200여 대 수용)',
    roadAddress: '서울 강남구 논현로 645',
    transportation: {
      bus: '000-1, 000-2, 123번 탑승 후 샘플예식장 정류장에서 하차 후 도보 이동 (약 도보 10분)',
      subway: '2호선 강남역 하차 후 도보 이동 (약 도보 5분)',
    },
    url: {
      google:
        'https://www.google.com/maps/place/%EC%B0%B8%EB%B0%94%EB%A1%9C%EB%B3%91%EC%9B%90/data=!3m1!4b1!4m6!3m5!1s0x357ca33f31d2dda9:0xe331c3048d55716f!8m2!3d37.5658043!4d127.0165817!16s%2Fg%2F11g8w8w0sc?entry=ttu',
      kakao: 'https://map.kakao.com/?map_type=TYPE_MAP&itemId=262230208&urlLevel=3&urlX=503671&urlY=1129516',
      naver: 'https://map.naver.com/p/entry/place/1895554446?placePath=%2Fhome&c=15.00,0,0,0,dh',
      tmap: 'https://tmap.life/1b14a0ff',
    },
    venue: '다이아몬드홀',
  },
  share: {
    text: '12월의 뜻깊고 아름다운 날, 저희가 결혼을 합니다. 많은 축복 부탁드립니다!',
    title: '저희 결혼합니다 :)',
  },
  alias: 'template_simple_1',
  weddingDate: {
    day: 31,
    month: 12,
    time: '13:00',
    year: 2025,
  },
};
