import React, { useState, useEffect } from 'react';
import { Fade } from 'react-reveal';

import styles from './Calendar.module.css';
import { generateCalendar } from '../../../../utils/generateCalendar';
import ornament from '../../../../assets/deco/wedding-ornament-3.svg';

const Calendar = ({ dbData }) => {
  const { year, month, day } = dbData.weddingDate;
  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    setCalendarData(generateCalendar(year, month));
  }, [year, month]);

  return (
    <div className={styles.calendar_wrapper}>
      <Fade bottom duration={1000}>
        <img src={ornament} alt='ornament' />
      </Fade>
      <Fade bottom duration={1000}>
        <h2>
          {year}년 {month}월
        </h2>
      </Fade>
      <div id='Calendar' className={styles.Calendar}>
        <table>
          <Fade bottom duration={1200}>
            <thead>
              <tr>
                <th>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
              </tr>
            </thead>
          </Fade>
          <tbody>
            {calendarData.map((week, i) => (
              <tr>
                <Fade bottom duration={1400 + i * 100}>
                  {week.map((dayOfCalendar) =>
                    dayOfCalendar.day ? (
                      <td
                        key={dayOfCalendar.day}
                        style={{
                          color: `rgba(${dbData.colors.deep}, 0.5)`,
                          ...(new Date(year, month - 1, dayOfCalendar.day).toDateString() ===
                            new Date(
                              `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
                            ).toDateString() && {
                            backgroundColor: `rgba(${dbData.colors.deep}, 0.5)`,
                            fontWeight: 'bold',
                            color: 'white',
                          }),
                        }}
                      >
                        {dayOfCalendar.day}
                      </td>
                    ) : (
                      <td key={new Date().getTime() - Math.random() * 1000} />
                    ),
                  )}
                </Fade>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
