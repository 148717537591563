import { useEffect, useState } from 'react';

const useTitleScrollHeight = () => {
  const [scrollPosForJump, setScrollPosForJump] = useState(0);

  useEffect(() => {
    const handleScrollPosForJump = () => {
      const invitationElement = document.getElementById('#invitation');
      if (invitationElement) {
        const invitationOffset = Math.min(invitationElement.offsetTop, window.innerHeight);
        setScrollPosForJump(invitationOffset);
      }
    };

    window.addEventListener('load', handleScrollPosForJump);

    return () => {
      window.removeEventListener('load', handleScrollPosForJump);
    };
  }, []);

  return window.innerHeight;
};

export default useTitleScrollHeight;
